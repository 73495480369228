import React, { Component, useEffect, useState } from "react";
import "./DonationList.css";
import { Fade } from "react-reveal";
import PullRequestCard from "../../components/pullRequestCard/PullRequestCard";
import pullRequestsData from "../../shared/opensource/pull_requests.json";
import axios from "axios";
import DonationRequestCard from "../../components/donationCard/DonationRequestCard";

const DonationList = ({ theme }) => {

  const [donationData, setDonationData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const fetchDonationData = async () => {
      try {
        const response = await axios.get('https://admin.komosabasamuel.com/api/donations');
        const donationData = [];
        response.data.data.forEach(element => {
          const date = new Date(element.created_at);

          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          };
          donationData.push(
            {
              id: element.id,
              name: element.full_name,
              amount: element.amount,
              created_at: date.toLocaleDateString('en-US', options),
              state: "OPEN",
              approved_by: element.approved_by.name
            }
          );
        });

        setDonationData(donationData);
        setIsDataFetched(true);
      } catch (error) {
        console.error('Error fetching donation data:', error);
      }
    };

    fetchDonationData();
  }, []);


  return (
    <div style={{ width: '100vw', margin: 0, padding: 0 }}>
      <div className="pull-requests-header-div" style={{ width: '100%' }}>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="pull-requests-header" style={{ color: theme.text }}>
            Approved Donation List
          </h1>
        </Fade>
      </div>
      <div className="pull-request-body-div" style={{ width: '100%' }}>
        {donationData.map((donation) => {
          return <DonationRequestCard pullRequest={donation} key={donation.id} />;
        })}
      </div>
    </div>

  );

}

export default DonationList;
