import React, { useState, useEffect } from 'react';
import axios from 'axios';
// ... other imports
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ImageGallery from './GalleryContainer'
import { Fade } from 'react-reveal';
const Gallery = ({ theme }) => {
  const [galleryData, setGalleryData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const response = await axios.get('https://admin.komosabasamuel.com/api/gallery');
        const imageData = [];
        response.data.data.forEach(element => {
          imageData.push(
            {
              src: `https://admin.komosabasamuel.com/storage/app/public/${element.image}`,
              width: 3,
              height: 4
            }
          );
        });

        setGalleryData(imageData);
        setIsDataFetched(true);
      } catch (error) {
        console.error('Error fetching gallery data:', error);
      }
    };

    fetchGalleryData();
  }, []);

  return (
    <div className="contact-main" style={{ width: '100vw', margin: 0, padding: 0 }}>
      <Header theme={theme} />
      <div className="basic-projects">
        <Fade bottom duration={2000} distance="40px">
          <div className="projects-heading-div">
            <div className="projects-heading-img-div">
              <img
                src={require(`../../assests/images/expriance_1.png`)}
                alt=""
              />
              {/* <ProjectsImg theme={theme} /> */}
            </div>
            <div className="projects-heading-text-div">
              <h1
                className="projects-heading-text"
                style={{ color: theme.text }}
              >
                Gallery of Komos Aba Samuel
              </h1>
              <p
                className="projects-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                Welcome to our Gallery! This vibrant and dynamic page is your gateway to a visual feast, showcasing the best of Komos Aba Samuel  work, events, and moments. Whether you're here to explore thier latest projects, relive unforgettable events, or simply enjoy stunning photography, our gallery has something for everyone.
              </p>
            </div>
          </div>
        </Fade>
        <Fade ottom duration={2000} distance="40px">
          {isDataFetched && <ImageGallery images={[...galleryData]} />}  

        </Fade>
      </div>
      
      <Footer theme={theme} />
      <TopButton theme={theme} />
    </div>
  );
};

export default Gallery;
