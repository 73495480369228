import React, { Component } from "react";
import "./PullRequestCard.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Fade } from "react-reveal";

class PullRequestCard extends Component {
  render() {
    const pullRequest = this.props.pullRequest;
    var iconPR;
    var bgColor;
    if (pullRequest["state"] === "OPEN") {
      iconPR = {
        iconifyClass: "octicon:git-pull-request",
        style: { color: "#28a745" },
      };
      bgColor = "#dcffe4";
    } else if (pullRequest["state"] === "MERGED") {
      iconPR = {
        iconifyClass: "octicon:git-merge",
        style: { color: "#6f42c1" },
      };
      bgColor = "#f5f0ff";
    } else {
      iconPR = {
        iconifyClass: "octicon:git-pull-request",
        style: { color: "#d73a49" },
      };
      bgColor = "#ffdce0";
    }


    return (
      <Fade bottom duration={2000} distance="40px">
        <div
          className="pull-request-card"
          style={{
            backgroundColor: bgColor,
            border: `1px solid ${iconPR.style.color}`,
          }}
        >
          <div className="pr-top">
            <div className="pr-header">
             
              <div className="pr-title-header">
                <p className="pr-title">
                  <a
                    href={pullRequest["url"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {pullRequest["title"]}
                  </a>
                </p>
                <p className="pr-subtitle">{pullRequest['createdAt']}</p>
              </div>
            </div>
            
          </div>
          <div className="pr-down">
            <div className="changes-repo">
              <p className="parent-repo">
                Posted By:{" "}
                  {pullRequest["posted_by"]}
                
              </p>
             
            </div>
            
          </div>
        </div>
      </Fade>
    );
  }
}

export default PullRequestCard;
