import React, { useCallback, useEffect, useState } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
 const ImageGallery = (images) => {
   const [currentImage, setCurrentImage] = useState(0);
   const [viewerIsOpen, setViewerIsOpen] = useState(false);

   const openLightbox = useCallback((event, { images, index }) => {
     setCurrentImage(index);
     setViewerIsOpen(true);
   }, []);

   const closeLightbox = () => {
     setCurrentImage(0);
     setViewerIsOpen(false);
   };

   console.log("image", images.images);

  return (
    <div>
      <Gallery photos={images.images} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={images.images.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};


export default ImageGallery;