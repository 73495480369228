import React, { Component, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { Fade } from "react-reveal";
import Modal from 'react-modal';
import axios from 'axios';
import "./ContactComponent.css";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { greeting, contactPageData } from "../../portfolio.js";
import DonationList from "../../containers/donationList/DonationList.js";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';

const ContactData = contactPageData.contactSection;



const Contact =({theme}) => {
  const [basicModal, setBasicModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phone_number: "",
    christianName: "",
    amount: "",
  });

  const toggleOpen = () => setBasicModal(!basicModal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { fullName, phone, christianName, amount } = formData;

    axios.post('https://admin.komosabasamuel.com/api/donation', {
      full_name: fullName,
      phone_number: phone,
      christian_name: christianName,
      amount: amount
    })
      .then(response => {
        alert('Donation registered successfully!');
        toggleOpen();
      })
      .catch(error => {
        console.error('There was an error registering the donation!', error);
      });
  };


    return (
      <div className="contact-main" style={{ width: '100vw', margin: 0, padding: 0 }}>
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-div">
                <img
                  src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}
                  alt=""
                />
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {ContactData["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {ContactData["description"]}
                </p>
                <SocialMedia theme={theme} />
                <div className="resume-btn-div" role="button"
                  onClick={toggleOpen}>

                  <Button
                    text="Donate for Church"

                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>
          <DonationList theme={theme} />
          <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Donate</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <form onSubmit={handleSubmit}>
                    <MDBRow className='mb-4'>
                      <MDBCol>
                        <MDBInput id='form6Example1' name="fullName" value={formData.fullName} onChange={handleChange} required label='Full name' />
                      </MDBCol>
                      <MDBCol>
                        <MDBInput id='form6Example2' name="christianName" value={formData.christianName} onChange={handleChange} required label='Christian name' />
                      </MDBCol>
                    </MDBRow>
                    <MDBInput wrapperClass='mb-4' id='form6Example3' name="amount" value={formData.amount} onChange={handleChange} required label='Amount' />
                    <MDBInput wrapperClass='mb-4' type='tel' name="phone_number" value={formData.phone} onChange={handleChange} required id='form6Example6' label='Phone' />
                    <MDBModalFooter>
                      <MDBBtn color='secondary' onClick={toggleOpen}>Close</MDBBtn>
                      <MDBBtn type="submit">Submit</MDBBtn>
                    </MDBModalFooter>
                  </form>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>

        </div>
        <Footer theme={theme} />
        <TopButton theme={theme} />


      </div>
    );
  
}

export default Contact;
