import React, { useState, useEffect } from 'react';
import { Chrono } from 'react-chrono';
import Carousel, { Modal, ModalGateway } from 'react-images';

const BlogContainer = ({ blogs }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    const handleCardMediaClick = (event) => {
      
      // Find the blog item that corresponds to the clicked media element
      const clickedElement = event.target.closest('.my-card-media');
      console.log(clickedElement);
      if (clickedElement) {
        const imageElement = clickedElement.querySelector('img'); // Select the image tag within the clicked element
        if (imageElement) {
          const imageSrc = imageElement.src; // Get the image src

          const filteredImage = blogs.filter((item) => {
            if (imageSrc === item.media.source.url) {
              return item;
            }
          });

          const mappedValue = filteredImage.map((value) => ({
            source: value.media.source.url,
            caption: value.cardTitle,
          }));

          setCurrentImage(mappedValue);
          setModalIsOpen(true);
        }
      }
    };

    // Add event listener to elements with the 'my-card-media' class
    document.addEventListener('click', handleCardMediaClick);

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('click', handleCardMediaClick);
    };
  }, [blogs]);

  const openLightbox = (selectedBlog) => {
    

    const filteredImage = blogs.filter((item) => {
      if (selectedBlog.title === item.title) {
        return item;
      }
    });

    const mappedValue = filteredImage.map((value) => ({
      source: value.media.source.url,
      caption: value.cardTitle,
    }));

    setCurrentImage(mappedValue);
    setModalIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage([]);
    setModalIsOpen(false);
  };

  return (
    <>
      <div style={{ width: '100%', height: '600px' }}>
        <Chrono
          items={blogs}
          mode="VERTICAL"
          // onItemSelected={(val) => openLightbox(val)}
          classNames={{
            cardMedia: 'my-card-media',
          }}
        />
      </div>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel views={currentImage} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

export default BlogContainer;
