import React, { useState, useEffect } from 'react';
import axios from 'axios';
// ... other imports
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import BlogContainer from './BlogContainer'
import { Fade } from 'react-reveal';
const BlogComponent = ({ theme }) => {
  const [blogData, setBlogData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.get('https://admin.komosabasamuel.com/api/blog');
        const imageData = [];
        response.data.data.forEach(item => {
          const date = new Date(item.created_at);

          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          };

          imageData.push(
            {
              id: item.id,
              title: date.toLocaleDateString('en-US', options),
              cardTitle: item.title,
              media: {
                source: {
                  url:
                    `https://admin.komosabasamuel.com/storage/app/public/${item.image}`
                },
                type: "IMAGE"
              },
              imageClass: "custom-image-class",
              cardSubtitle: `Created By: ${item.created_by.name}`,
              cardDetailedText: item.description,
            },
          );
        });

        setBlogData(imageData);
        setIsDataFetched(true);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchBlogData();
  }, []);

  return (
    <div className="contact-main" style={{ width: '100vw', margin: 0, padding: 0 }}>
      <Header theme={theme} />
      <div className="basic-projects">
        <Fade bottom duration={2000} distance="40px">
          <div className="projects-heading-div">
            <div className="projects-heading-img-div">
              <img
                src={require(`../../assests/images/expriance_1.png`)}
                alt=""
              />
              {/* <ProjectsImg theme={theme} /> */}
            </div>
            <div className="projects-heading-text-div">
              <h1
                className="projects-heading-text"
                style={{ color: theme.text }}
              >
                Blog of Komos Aba Samuel
              </h1>
              <p
                className="projects-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                Welcome to our Blog! This vibrant and dynamic page is your gateway to a visual feast, showcasing the best of Komos Aba Samuel  work, events, and moments. Whether you're here to explore thier latest projects, relive unforgettable events, or simply enjoy stunning photography, our gallery has something for everyone.
              </p>
            </div>
          </div>
        </Fade>
        <Fade ottom duration={2000} distance="40px">
          {isDataFetched && <BlogContainer blogs={blogData} theme={theme} />}  

        </Fade>
      </div>
      
      <Footer theme={theme} />
      <TopButton theme={theme} />
    </div>
  );
};

export default BlogComponent;
